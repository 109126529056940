export const pop_list = [
  [
    {
      id:1,
      text: '量少'
    },
    {
      id:2,
      text: '一般'
    },
    {
      id:3,
      text: '量多'
    },
  ],
  [
    {
      id:1,
      text: '有'
    },
    {
      id:2,
      text: '无'
    },
  ],
  [
    {
      id:1,
      text: '规则'
    },
    {
      id:2,
      text: '不规则'
    },
  ],
  [
    {
      id:1,
      text: '有'
    },
    {
      id:2,
      text: '无'
    },
  ],
]