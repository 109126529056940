<template>
  <div class="medical">
    <div class="type_title">
      基本信息
    </div>
    <van-cell-group>
      <van-cell title="民族" value="汉族" />
      <van-cell title="婚姻" value="未婚" />
      <van-cell title="是否怀孕" value="已怀孕" />
      <van-cell title="职业" value="未知" />
    </van-cell-group>
    <div class="type_title">
      既往史
    </div>
    <div class="history_line">
      <div class="history_items">
        <div class="history_left">
           <van-icon name="checked" size="20px" color="#3388FF" />
        </div>
        <div class="history_right">
          <div class="history_title">
            急性胃炎
          </div>
          <div class="history_sub">
            门诊 医师王浩 2020-09-28 09:00:02
          </div>
        </div>
      </div>
      <div class="history_items">
        <div class="history_left last_left">
           <van-icon name="checked" size="20px" color="#3388FF" />
        </div>
        <div class="history_right">
          <div class="history_title">
            急性胃炎
          </div>
          <div class="history_sub">
            门诊 医师王浩 2020-09-28 09:00:02
          </div>
        </div>
      </div>
    </div>
    <div class="type_title">
      <span>过敏史</span>
      <div class="add_btn" @click="jump_to_allergic()">添加</div>
    </div>
    <div class="ill_history">
      <div class="ill_btn">青霉素</div>
      <div class="ill_btn">海鲜过敏</div>
    </div>
    <div class="type_title">
      <span>家族史</span>
      <div class="add_btn" @click="jump_to_family()">添加</div>
    </div>
    <div class="family_history">
      <div class="family_items">
        <div class="family_left">
          <img :src="'./img/family-imgs/user-header-2.png'" alt="" width="28">
        </div>
        <div class="family_center">
          <div class="family_title">
            高血压，糖尿病
          </div>
          <div class="family_sub">
            生存状态：患病
          </div>
        </div>
        <div class="family_right">
          父母
        </div>
      </div>
      <div class="family_items">
        <div class="family_left">
          <img :src="'./img/family-imgs/user-header-2.png'" alt="" width="28">
        </div>
        <div class="family_center">
          <div class="family_title">
            高血压，糖尿病
          </div>
          <div class="family_sub">
            生存状态：患病
          </div>
        </div>
        <div class="family_right">
          父母
        </div>
      </div>
    </div>

     <div class="type_title">
      <span>个人史</span>
      <div class="add_btn" @click="jump_to_person()">添加</div>
    </div>
    <div class="ill_history">
      <div class="ill_btn">吸烟</div>
      <div class="ill_btn">酗酒</div>
      <div class="ill_btn">吸毒</div>
    </div>
    <div class="type_title">
      生育史
    </div>
    <van-cell-group>
      <van-cell title="初潮" value="14岁" />
      <van-cell title="每次持续" value="未婚" />
      <van-cell title="未经月经" value="已怀孕" />
      <van-cell title="绝经年龄" value="未知" />

      <van-cell title="月经周期" value="5天" />
      <van-cell title="经量" :value="param1" is-link @click="cell_click(0)" />
      <van-cell title="痛经" :value="param2" is-link @click="cell_click(1)" />
      <van-cell title="经期" :value="param3" is-link @click="cell_click(2)" />

      <van-cell title="妊辰" value="1次" />
      <van-cell title="顺产" value="1胎" />
      <van-cell title="流产" value="1胎" />
      <van-cell title="早产" value="1胎" />

      <van-cell title="死产" value="1胎" />
      <van-cell title="难产" :value="param4" is-link @click="cell_click(3)" />
      <van-cell title="子" value="1个" />
      <van-cell title="女" value="1个" />
    </van-cell-group>

    <van-popup v-model="show_picker" position="bottom">
      <van-picker
        show-toolbar
        :columns="members"
        @confirm="onConfirm"
        @cancel="show_picker = false"
      />
    </van-popup>
  </div>
</template>
<script>
import { pop_list } from './config'
export default {
  data () {
    return {
      loading: false,
      id: this.$route.query.id,
      members: [],
      show_picker: false,
      pop_list,
      pop_index: 0,
      param1: '一般',
      param2: '无',
      param3: '规则',
      param4: '无',
    }
  },
  computed: {
  },
  components: {
  },
  created () {
  },
  methods: {
    cell_click(v) {
      this.show_picker = true
      this.members = this.pop_list[v]
      this.pop_index = v
    },
    onConfirm(value){
      this.show_picker = false
      switch (this.pop_index) {
      case 0:
        this.param1 = value.text
        break
      case 1:
        this.param2 = value.text
        break
      case 2:
        this.param3 = value.text
        break
      case 3:
        this.param4 = value.text
      }
    },
    jump_to_allergic() {
      this.$router.push({
        path: '/add-allergic-history'
      })
    },
    jump_to_family() {
      this.$router.push({
        path: '/add-family-history'
      })
    },
    jump_to_person() {
      this.$router.push({
        path: '/add-person-history'
      })
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.medical {
  background: #F2F7F8;
}

.type_title {
  padding: 12px 16px;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

>>>.van-cell__title {
  flex: none;
  width: 25%;
  margin-right: 12px;
}

>>>.van-cell__value {
  text-align: left;
  color: #323232;
}

.history_line {
  background: #fff;
  padding: 20px 24px 0 24px;
}

.history_items {
  display: flex;
  align-content: center;
  height: 60px;
}

.history_left {
  position: relative;
  margin-right: 12px;

  &::after {
    content: '';
    width: 2px;
    height: 60px;
    background: #F2F3F5;
    position: absolute;
    left: 9px;
    top: 19px;
  }

  &.last_left::after {
    display: none;
  }
}

.history_title {
  font-size: 14px;
  line-height: 20px;
  color: #323233;
  font-weight: 500;
}

.history_sub {
  font-size: 12px;
  line-height: 16px;
  color: #7D7E80;
  margin-top: 4px;
}

.add_btn {
  font-size: 14px;
  color: #0088FF;
}

.ill_history {
  background: #fff;
  padding: 16px;
  padding-bottom: 6px;
  display: flex;
  flex-wrap: wrap;
}

.ill_btn {
  height: 32px;
  background: #FF4444;
  border-radius: 2px;
  padding: 0 15px;
  min-width: 80px;
  text-align: center;
  font-size: 13px;
  line-height: 32px;
  color: #FFFFFF;
  margin: 0 8px 10px 0;
}

.family_history {
  background: #fff;
}

.family_items {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.family_center {
  flex: 1;
  margin: 0 15px;
}

.family_title {
  font-size: 14px;
  line-height: 20px;
  color: #323233;
  font-weight: 500;
}

.family_sub {
  font-size: 12px;
  line-height: 16px;
  color: #7D7E80;
  margin-top: 4px;
}

.family_right {
  font-size: 14px;
  line-height: 38px;
  color: #07AA00;
}
</style>
